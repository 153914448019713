import axios from "../../exios";
import moment from "moment";
import qs from "qs";
import {all, put, select, takeLatest} from 'redux-saga/effects';
import {OPEN_RR_INVOICES_REPORT} from "../Actions/openRrInvoicesReport";
import {LOAD_RR_INVOICES_REPORT} from "../Actions/loadRrInvoiceReport";
import {DOWNLOAD_FAKT_RR_INVOICES} from "../Actions/downloadFaktRrInvoices";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {OPEN_INVOICES_REPORT} from "../Actions/openInvoicesReport";
import {LOAD_INVOICES_REPORT} from "../Actions/loadInvoicesReport";
import {DOWNLOAD_FAKT_INVOICES} from "../Actions/downloadFaktInvoices";
import {getAxiosConfig} from "./tenantsSaga";
import {busyCall} from "./businessSaga";
import {getApiUrl} from "./utils";
import {downloadFile} from "./downloadFile";
import {DOWNLOAD_RR_INVOICES_AS_PDF} from "../Actions/downloadRrInvoicesAsPdf";
import {DOWNLOAD_RR_INVOICES_AS_ZIP} from "../Actions/downloadRrInvoicesAsZip";
import {DOWNLOAD_INVOICES_AS_PDF} from "../Actions/downloadInvoicesAsPdf";
import {DOWNLOAD_INVOICES_AS_ZIP} from "../Actions/downloadInvoicesAsZip";
import {DOWNLOAD_RRINVOICES_AS_EINVOICES} from "../Actions/downloadRrInvoicesAsEInvoices";
import {DOWNLOAD_INVOICES_AS_JPK} from "../Actions/downloadInvoicesAsJpk";


function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* handleOpenRrInvoicesReport({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {rrInvoiceReport:{}}));
}

function* setRrLoading(tenantId, isLoading, stateOverride) {
    const oldReport = yield select(s => s.currentWindow.rrInvoiceReport);

    const rrInvoiceReport = {
        ...(oldReport || {}),
        ...(stateOverride || {}),
        isLoading,
    };

    yield put(resetCurrentTenantWindow(tenantId, {rrInvoiceReport}));
}

function* handleLoadRrInvoicesReport({tenantId, dateFrom, dateTo}) {
    yield setRrLoading(tenantId, true);
    const config = yield getConfig(tenantId);
    const params = {
        dateFrom: moment(dateFrom).format(),
        dateTo: moment(dateTo).format()
    }
    const {data} = yield busyCall(axios.get, getApiUrl('/reports/rr-invoices/all', tenantId), {...config, params});

    yield setRrLoading(tenantId, false, {data});
}

function* handleDownloadRrFile(tenantId, ids, accept, fileExt, modes = undefined) {
    yield setRrLoading(tenantId, true);
    yield downloadFile(
        tenantId,
        getApiUrl('/reports/rr-invoices/selected', tenantId),
        `Faktury VAT-RR.${fileExt}`,
        {
            params: {
                ids,
                modes
            },
            paramsSerializer: params => qs.stringify(params),
            headers: {
                Accept: accept
            }
        });
    yield setRrLoading(tenantId, false);
}

function* handleDownloadFaktFile({tenantId, ids}) {
    yield handleDownloadRrFile(tenantId, ids, 'application/vnd.fakt+xml', 'xml');
}

function* handleDownloadRrInvoicesAsPdf({tenantId, ids, modes}) {
    yield handleDownloadRrFile(tenantId, ids, 'application/pdf', 'pdf', modes);
}

function* handleDownloadRrInvoicesAsZip({tenantId, ids, modes}) {
    yield handleDownloadRrFile(tenantId, ids, 'application/zip', 'zip', modes);
}

function* handleDownloadRrInvoicesAsEInvoices({tenantId, ids}) {
    yield handleDownloadRrFile(tenantId, ids, 'application/vnd.w-firma+ubl+xml', 'zip');
}

function* handleOpenInvoicesReport({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {invoicesReport:{}}));
}

function* setLoading(tenantId, isLoading, stateOverride) {
    const oldReport = yield select(s => s.currentWindow.invoicesReport);

    const invoicesReport = {
        ...(oldReport || {}),
        ...(stateOverride || {}),
        isLoading,
    };

    yield put(resetCurrentTenantWindow(tenantId, {invoicesReport}));
}

function* handleLoadInvoicesReport({tenantId, dateFrom, dateTo}) {
    yield setLoading(tenantId, true);
    const config = yield getConfig(tenantId);
    const params = {
        dateFrom: moment(dateFrom).format(),
        dateTo: moment(dateTo).format()
    }
    const {data} = yield busyCall(axios.get, getApiUrl('/reports/invoices/all', tenantId), {...config, params});

    yield setLoading(tenantId, false, {data})
}

function* handleDownloadInvoiceFile(tenantId, ids, accept, fileExt) {
    yield setLoading(tenantId, true);
    yield downloadFile(
        tenantId,
        getApiUrl('/reports/invoices/selected', tenantId),
        `Faktury VAT.${fileExt}`,
        {
            params: {
                ids
            },
            paramsSerializer: params => qs.stringify(params),
            headers: {
                Accept: accept
            }
        });
    yield setLoading(tenantId, false);
}

function* handleDownloadFaktInvoices({tenantId, ids}) {
    yield handleDownloadInvoiceFile(tenantId, ids, 'application/vnd.fakt+xml', 'xml');
}

function* handleDownloadInvoicesAsPdf({tenantId, ids}) {
    yield handleDownloadInvoiceFile(tenantId, ids, 'application/pdf', 'pdf');
}

function* handleDownloadInvoicesAsZip({tenantId, ids}) {
    yield handleDownloadInvoiceFile(tenantId, ids, 'application/zip', 'zip');
}

function* handleDownloadInvoicesAsJpk({tenantId, ids}) {
    yield handleDownloadInvoiceFile(tenantId, ids, 'application/vnd.wfirma+jpk_v7+xml', 'xml');
}

export function* reportsSaga(){
    yield all([
        takeLatest(OPEN_RR_INVOICES_REPORT, handleOpenRrInvoicesReport),
        takeLatest(LOAD_RR_INVOICES_REPORT, handleLoadRrInvoicesReport),
        takeLatest(DOWNLOAD_FAKT_RR_INVOICES, handleDownloadFaktFile),
        takeLatest(DOWNLOAD_RR_INVOICES_AS_PDF, handleDownloadRrInvoicesAsPdf),
        takeLatest(DOWNLOAD_RR_INVOICES_AS_ZIP, handleDownloadRrInvoicesAsZip),
        takeLatest(DOWNLOAD_RRINVOICES_AS_EINVOICES, handleDownloadRrInvoicesAsEInvoices),
        takeLatest(OPEN_INVOICES_REPORT, handleOpenInvoicesReport),
        takeLatest(LOAD_INVOICES_REPORT, handleLoadInvoicesReport),
        takeLatest(DOWNLOAD_FAKT_INVOICES, handleDownloadFaktInvoices),
        takeLatest(DOWNLOAD_INVOICES_AS_PDF, handleDownloadInvoicesAsPdf),
        takeLatest(DOWNLOAD_INVOICES_AS_ZIP, handleDownloadInvoicesAsZip),
        takeLatest(DOWNLOAD_INVOICES_AS_JPK, handleDownloadInvoicesAsJpk)
    ])
}